import axios from 'axios';
import { iniciouRequisicaoWeb, finalizouRequisicaoWeb } from '../actions/requisicoesAjaxActions';

const instanciaAxiosComLoading = axios.create();

const criacaoDaApiDeConsultaPublica = instanciaDoAxios => ({
  axios: instanciaDoAxios,
  get: (rota, dados, header) => instanciaDoAxios.get(`${rota}`, dados, header),
  post: (rota, dados, header) => instanciaDoAxios.post(`${rota}`, dados, header),
  put: (rota, dados, header) => instanciaDoAxios.put(`${rota}`, dados, header),
  delete: (rota, dados, header) => instanciaDoAxios.delete(`${rota}`, dados, header),
});

export const apiDaConsultaPublica = () => criacaoDaApiDeConsultaPublica(instanciaAxiosComLoading);

export const configurarAxios = (store) => {
  const requestInterceptor = instanciaAxios => instanciaAxios.interceptors.request.use((config) => {
    store.dispatch(iniciouRequisicaoWeb());
    return config;
  });

  const responseInterceptor = instanciaAxios => instanciaAxios.interceptors.response.use(
    (response) => {
      store.dispatch(finalizouRequisicaoWeb());
      return response;
    },
    (error) => {
      store.dispatch(finalizouRequisicaoWeb());
      return Promise.reject(error);
    },
  );

  requestInterceptor(instanciaAxiosComLoading);
  responseInterceptor(instanciaAxiosComLoading);
};
