import React from 'react';
import PropTypes from 'prop-types';
import CriterioItem from './CriterioItem';

export default function ListaDeCriterios({
  titulo, criterios, mensagemParaListaVazia, atendido,
}) {
  const possuiCriterios = criterios && criterios.length;

  return (
    <div className="criterios__grupo">
      <h3 className="titulo titulo_pequeno criterios__titulo">{titulo}</h3>

      <div className="criterios__lista">
        {possuiCriterios ? (
          criterios.map((criterio, indice) => (
            <CriterioItem nomeDoCriterio={criterio} key={indice} foiAtendido={atendido} />
          ))
        ) : (
          <p className="criterios__mensagem">{mensagemParaListaVazia}</p>
        )}
      </div>
    </div>
  );
}

ListaDeCriterios.propTypes = {
  titulo: PropTypes.string,
  criterios: PropTypes.array,
  mensagemParaListaVazia: PropTypes.string,
  atendido: PropTypes.bool,
};
