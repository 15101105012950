import { OBTEVE_SORTEADOS, REMOVEU_SORTEADOS } from '../actions/actionTypes';
import initialState from './initialState';

export default (state = initialState.sorteados, action) => {
  switch (action.type) {
    case OBTEVE_SORTEADOS:
      return {
        ...action.sorteados.data,
      };
    case REMOVEU_SORTEADOS:
      return {
        ...action.sorteados,
      };
    default:
      return state;
  }
};
