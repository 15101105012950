import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default (state = initialState.requisicoesAjax, action) => {
  if (action.type === types.INICIOU_REQUISICAO_WEB)
    return state + 1
  else if (action.type === types.FINALIZOU_REQUISICAO_WEB)
    return state === 0 ? 0 : state - 1

  return state
}
