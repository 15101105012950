import React from 'react';
import { ReactComponent as LogoAgehab } from '../Logos/logo-agehab.svg';
import { ReactComponent as LogoSeinfra } from '../Logos/logo-seinfra.svg';
import { ReactComponent as LogoGoverno } from '../Logos/governo-do-estado.svg';
import '../../estilos/estilos.scss'

const Header = () => {
    return (
        <header className="cabecalho">
            <div className="cabecalho__container">
                <LogoAgehab className="cabecalho__logo-agehab" alt="AGEHAB - Agência de Habitação Popular"/>
                <LogoSeinfra className="cabecalho__logo-seinfra" alt="SEINFRA - Secretaria de Estado de Infraestrutura de Mato Grosso do Sul"/>
                <LogoGoverno className="cabecalho__logo-estado" alt="Governo do Estado de Mato Grosso do Sul"/>
            </div>
        </header>
    )
}

export default Header;