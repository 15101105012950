import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

const Dialogo = ({ icone, titulo, mensagem, textoDoBotaoFechar, fecharDialogo }) => {
  return (
    <div className={`dialogo dialogo_mini dialogo_consulta-publica dialogo_ativo`}>
      <div className="dialogo__container">
        <div className="dialogo__corpo">
        <div className="dialogo__icone"><FontAwesomeIcon icon={icone} size="6x" /></div>
          <h1 className="dialogo__titulo">{titulo}</h1>
          <p className="dialogo__mensagem">{mensagem}</p>
        </div>
    
        <div className="dialogo__rodape dialogo__rodape_centralizado">
          <button onClick={fecharDialogo} className="botao botao_sucesso">{textoDoBotaoFechar}</button>
        </div>
        
        <button onClick={fecharDialogo} className="dialogo__botao-fechar" aria-label="Fechar diálogo">
          <FontAwesomeIcon icon={faTimes} size="1x" />
        </button>
      </div>
    </div>
  );
}

Dialogo.propTypes = {
  icone: PropTypes.object,
  titulo: PropTypes.string,
  mensagem: PropTypes.string,
  textoDoBotaoFechar: PropTypes.string,
  fecharDialogo: PropTypes.func
};

export default Dialogo;
