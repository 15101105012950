import { apiDaConsultaPublica } from './axios';
//TODO refatorar para trazer as URLS de outro logar
var urlBase = 'http://agrupador.habix.agehab.ms.gov.br/api';

const hostAtual = window.location.host;
if (hostAtual.includes('hom')){
  urlBase = 'http://hom.agrupador.habix.agehab.ms.gov.br/api';
}else if (hostAtual.includes('digix') || hostAtual.includes('azurecontainer')) {
    urlBase = 'http://habixagrupador.brazilsouth.azurecontainer.io/api';
}

const ehAmbienteDeTesteE2E = process != null && process.env != null && process.env.REACT_APP_AMBIENTE_E2E;
if(window.location.host.includes('localhost') && !ehAmbienteDeTesteE2E){
  urlBase = 'http://localhost:4000/agrupador/api';
}

const obterCriterios = async (idDaListagemDeHabilitados, numeroDaInscricao) => {
  const url = `${urlBase}/listagensDeAptos/${idDaListagemDeHabilitados}/aptos/${numeroDaInscricao}/criterios`;
  return await apiDaConsultaPublica().get(url);
};

export default { obterCriterios };
