import React, { Component } from 'react';
import '../../estilos/estilos.scss';
import PropTypes from 'prop-types';
import { Cartao } from '@bit/digix.digixui.cartao';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import SorteadoItemApi from './SorteadoItemApi';
import ListaDeCriterios from '../ListaDeCriterios';

class SorteadoItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      criterios: {
        criteriosAtendidosPeloApto: [],
        criteriosNaoAtendidosPeloApto: [],
        criteriosDosGrupos: [],
      }
    };
  }

  componentDidUpdate(propsAntigas) {
    if (propsAntigas.sorteado.nomeDoSorteado === this.props.sorteado.nomeDoSorteado) return;

    this.setState({ 
      criterios: {
        criteriosAtendidosPeloApto: [],
        criteriosNaoAtendidosPeloApto: [],
        criteriosDosGrupos: [],
      }
    });
  }

  obterInformacoesDeCriterios = async() => {
    let {criteriosAtendidosPeloApto, criteriosNaoAtendidosPeloApto} = this.state.criterios;
    let jaObteveCriteriosParaOSorteado = (criteriosAtendidosPeloApto && criteriosAtendidosPeloApto.length !== 0)
      || (criteriosNaoAtendidosPeloApto && criteriosNaoAtendidosPeloApto.length !== 0);
    
    if (jaObteveCriteriosParaOSorteado) return;

    const criterios = await SorteadoItemApi.obterCriterios(
      this.props.sorteado.idDaListagemDeHabilitados,
      this.props.sorteado.numeroDaInscricao,
    );

    this.setState({
      criterios,
    });
  }

  deveExibirInformacaoDeEnquadramento() {
    const { sorteado } = this.props;

    return sorteado.grupoOriginal !== sorteado.grupoSorteado;
  }

  exibirInformacaoDeEnquadramento() {
    const { sorteado } = this.props;

    return (
      <div className="mensagem">
        <FontAwesomeIcon icon={faInfoCircle} size="1x" />
        <p className="mensagem__texto">
          {`Candidato habilitado no: "${sorteado.grupoOriginal}", 
            sorteado para completar vagas no: "${sorteado.grupoSorteado}", conforme descrito na: `}
          <a
            href="http://www.agehab.ms.gov.br/wp-content/uploads/2018/05/PORTARIA-n%C2%B0-163-de-2016-MINHA-CASA-MINHA-VIDA.pdf"
            target="blank"
            className="mensagem__link"
          >
            Portaria 163 de 06/05/2016, item 4.11
          </a>
        </p>
      </div>
    );
  }

  render() {
    const { sorteado } = this.props;
    const { criterios } = this.state;
    const criteriosAtendidosPeloSorteado = [...new Set(criterios.criteriosAtendidosPeloApto)]
    const criteriosNaoAtendidosPeloSorteado = [...new Set(criterios.criteriosNaoAtendidosPeloApto)]

    return (
      <li className="lista__item">
        <Cartao
          titulo={
            (<>
              <span className="cartao__classificacao">{sorteado.classificacao}º</span>
              <span className="cartao__nome">{sorteado.nomeDoSorteado}</span>
            </>)
          }
          subtitulo={
            (<>
              <span className="cartao__subtitulo-item cartao__subtitulo-item_sem-quebra-de-linha u-mostrar-no-tablet">
                Lista:
                {' '}
                <span className="cartao__subtitulo-item_lista cartao__subtitulo-item_sem-quebra-de-linha">{sorteado.lista}</span>
              </span>
              <span className="cartao__subtitulo-item u-mostrar-no-tablet">
                {sorteado.nomeDoEmpreendimento}
              </span>
              <span className="cartao__subtitulo-item cartao__subtitulo-item_sem-quebra-de-linha">
                Grupo:
                {' '}
                {sorteado.grupoSorteado}
              </span>
              <span className="cartao__subtitulo-item cartao__subtitulo-item_sem-quebra-de-linha u-mostrar-no-tablet">
                CPF:
                {' '}
                {sorteado.cpfDoSorteado}
              </span>
            </>)
          }
          cor="info"
          comDestaque
          comConteudoExpansivo
          comCabecalhoResponsivo
          textoDoBotaoDeExpansaoFechado={(<>Mais informações <FontAwesomeIcon icon={faInfoCircle} /></>)}
          textoDoBotaoDeExpansaoAberto={(<>Menos informações <FontAwesomeIcon icon={faInfoCircle} /></>)}
          classNameDoBotaoDeExpansao="botao botao_cor-info botao_contorno botao_pequeno botao_com-icone-para-direita"
          className={`cartao_cor-${sorteado.lista.toLowerCase()}`}
          eventoDoBotaoDeExpansaoAoAbrirCartao={this.obterInformacoesDeCriterios}>
            {this.deveExibirInformacaoDeEnquadramento() && this.exibirInformacaoDeEnquadramento()}

            <div className="cartao__texto-grupo">
              <p className="cartao__texto u-mostrar-apenas-no-tablet">
                Grupo:
                {' '}
                {sorteado.grupoSorteado}
              </p>
              <p className="cartao__texto u-mostrar-no-tablet">
                Cidade:
                {' '}
                {sorteado.nomeDaCidade}
              </p>
            </div>

            <div className="criterios">
              <ListaDeCriterios
                titulo="Lista de critérios atendidos"
                criterios={criteriosAtendidosPeloSorteado}
                mensagemParaListaVazia="Nenhum critério atendido"
                atendido
              />
              <ListaDeCriterios
                titulo="Lista de critérios não atendidos"
                criterios={criteriosNaoAtendidosPeloSorteado}
                mensagemParaListaVazia="Todos os critérios foram atendidos"
              />
            </div>
        </Cartao>
      </li>
    );
  }
}

SorteadoItem.propTypes = {
  sorteado: PropTypes.object,
};

export default SorteadoItem;
