import { combineReducers } from 'redux';
import requisicoesAjax from './requisicoesAjax';
import sorteados from './sorteados';

const appReducer = combineReducers({
  requisicoesAjax,
  sorteados,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT') state = undefined;

  return appReducer(state, action);
};

export default rootReducer;
