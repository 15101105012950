import { OBTEVE_SORTEADOS, REMOVEU_SORTEADOS } from './actionTypes';
import SorteadorApi from '../servicos/sorteadorApi';

export const carregouSorteados = sorteados => ({ type: OBTEVE_SORTEADOS, sorteados });
export const deletouSorteados = () => ({ type: REMOVEU_SORTEADOS, sorteados: {} });

export const obterSorteados = (identificadorDoSorteado, pagina) => async (dispatch) => {
  const sorteados = await SorteadorApi.obterSorteados(identificadorDoSorteado, pagina);

  dispatch(carregouSorteados(sorteados));
};

export const deletarSorteados = () => (dispatch) => {
  dispatch(deletouSorteados());
};
