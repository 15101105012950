import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faArrowToLeft,
  faArrowLeft,
  faArrowToRight,
  faArrowRight,
} from '@fortawesome/pro-regular-svg-icons';

class SeletorDePagina extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantidadeMaximaDeBotoes: 5,
      quantidadeDeBotoesAEsquerda: 2,
      quantidadeDeBotoesADireita: 2,
      primeiroBotaoDePaginaVisivel: 1,
      ultimoBotaoDePaginaVisivel: 1,
    };
  }

  navegarParaAPagina(pagina) {
    this.props.navegarParaAPagina(pagina);
  }

  possuiProximaPagina() {
    return this.props.paginaAtual < this.props.totalDePaginas;
  }

  possuiPaginaAnterior() {
    return this.props.paginaAtual > 1;
  }

  construirBotaoDePrimeiraPagina() {
    return (
      this.possuiPaginaAnterior() && (
        <li className="paginacao__item">
          <button
            className="paginacao__navegacao paginacao__navegacao_esquerda"
            title="Voltar para primeira página"
            onClick={() => this.navegarParaAPagina(1)}
          >
            <FontAwesomeIcon icon={faArrowToLeft} className="paginacao__icone far fa-lg fa_cor-terciaria" />
          </button>
        </li>
      )
    );
  }

  construirBotaoDePaginaAnterior() {
    return (
      this.possuiPaginaAnterior() && (
        <li className="paginacao__item">
          <button
            className="paginacao__navegacao paginacao__navegacao_esquerda"
            title="Voltar para página anterior"
            onClick={() => this.navegarParaAPagina(this.props.paginaAtual - 1)}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="paginacao__icone far fa-lg fa_cor-terciaria" />
          </button>
        </li>
      )
    );
  }

  construirBotaoDeProximaPagina() {
    return (
      this.possuiProximaPagina() && (
        <li className="paginacao__item">
          <button
            className="paginacao__navegacao paginacao__navegacao_direita"
            title="Ir para próxima página"
            onClick={() => this.navegarParaAPagina(this.props.paginaAtual + 1)}
          >
            <FontAwesomeIcon icon={faArrowRight} className="paginacao__icone far fa-lg fa_cor-terciaria" />
          </button>
        </li>
      )
    );
  }

  construirBotaoDeUltimaPagina() {
    return (
      this.possuiProximaPagina() && (
        <li className="paginacao__item">
          <button
            className="paginacao__navegacao paginacao__navegacao_direita"
            title="Ir para última página"
            onClick={() => this.navegarParaAPagina(this.props.totalDePaginas)}
          >
            <FontAwesomeIcon icon={faArrowToRight} className="paginacao__icone far fa-lg fa_cor-terciaria" />
          </button>
        </li>
      )
    );
  }

  obterInformacaoDePaginacao() {
    return (
      <p className="paginacao__info">
        Página
        {' '}
        <strong>{this.props.paginaAtual}</strong>
        {' '}
        de
        {' '}
        <strong>{this.props.totalDePaginas}</strong>
      </p>
    );
  }

  componentDidUpdate(propsAntigas) {
    const { paginaAtual, totalDePaginas } = this.props;
    const {
      quantidadeMaximaDeBotoes,
      quantidadeDeBotoesAEsquerda,
      quantidadeDeBotoesADireita,
    } = this.state;

    if (
      paginaAtual === propsAntigas.paginaAtual
      && totalDePaginas === propsAntigas.totalDePaginas
    ) {
      return;
    }

    if (totalDePaginas <= quantidadeMaximaDeBotoes) {
      this.setState({
        primeiroBotaoDePaginaVisivel: 1,
        ultimoBotaoDePaginaVisivel: totalDePaginas,
      });

      return;
    }

    const estaNasPrimeirasPaginas = paginaAtual <= quantidadeDeBotoesAEsquerda;

    if (estaNasPrimeirasPaginas) {
      this.setState({
        primeiroBotaoDePaginaVisivel: 1,
        ultimoBotaoDePaginaVisivel: quantidadeMaximaDeBotoes,
      });

      return;
    }

    const estaNasUltimasPaginas = paginaAtual > totalDePaginas - quantidadeDeBotoesADireita;

    if (estaNasUltimasPaginas) {
      this.setState({
        primeiroBotaoDePaginaVisivel: totalDePaginas - 4,
        ultimoBotaoDePaginaVisivel: totalDePaginas,
      });

      return;
    }

    this.setState({
      primeiroBotaoDePaginaVisivel: paginaAtual - quantidadeDeBotoesAEsquerda,
      ultimoBotaoDePaginaVisivel: paginaAtual + quantidadeDeBotoesADireita,
    });
  }

  construirBotaoDePagina(indice, paginaAtual) {
    if (indice === paginaAtual) {
      return (
        <li className="paginacao__item" key={indice}>
          <button
            className="paginacao__link paginacao__link_ativo"
            aria-label={`Página ${indice}`}
            aria-current="page"
          >
            {' '}
            {indice}
          </button>
        </li>
      );
    }

    return (
      <li className="paginacao__item" key={indice}>
        <button
          className="paginacao__link"
          aria-label={`Página ${indice}`}
          onClick={() => this.navegarParaAPagina(indice)}
        >
          {' '}
          {indice}
        </button>
      </li>
    );
  }

  render() {
    const botoes = [];
    const { paginaAtual, totalDePaginas } = this.props;
    const { primeiroBotaoDePaginaVisivel, ultimoBotaoDePaginaVisivel } = this.state;

    for (let i = primeiroBotaoDePaginaVisivel; i <= ultimoBotaoDePaginaVisivel; i++) {
      const botao = this.construirBotaoDePagina(i, paginaAtual);

      botoes.push(botao);
    }

    return (
      <div className={`paginacao paginacao_cor-terciaria paginacao_com-alinhamento-a-direita u-margem-superior-grande ${totalDePaginas === 1 ? 'u-escondido' : ''}`} role="navigation" aria-label="Paginação">
        <div className="paginacao__container">
          {this.obterInformacaoDePaginacao()}

          <ol className="paginacao__lista">
            {this.construirBotaoDePrimeiraPagina()}
            {this.construirBotaoDePaginaAnterior()}
            {paginaAtual && botoes}
            {this.construirBotaoDeProximaPagina()}
            {this.construirBotaoDeUltimaPagina()}
          </ol>
        </div>
      </div>
    );
  }
}

SeletorDePagina.propTypes = {
  paginaAtual: PropTypes.number,
  totalDePaginas: PropTypes.number,
  navegarParaAPagina: PropTypes.func,
};

SeletorDePagina.defaultProps = {
  paginaAtual: 1,
  totalDePaginas: 1,
};

export default SeletorDePagina;
