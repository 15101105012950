import React from 'react';
import PropTypes from 'prop-types';

const CampoTextBox = ({
  label, placeholder, type, name, required, onChange,
}) => {
  const inputProps = {
    label,
    placeholder,
    type,
    name,
    required,
    onChange,
  };

  return (
    <div className="grade__coluna">
      <label className="u-escondido">{inputProps.label}</label>
      <input {...inputProps} />
    </div>
  );
};

CampoTextBox.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.string,
  onChange: PropTypes.func,
};

export default CampoTextBox;
