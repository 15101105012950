import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BlankSlate = ({ icone, tamanhoDoIcone, mensagem }) => (
  <div className="pagina-em-branco">
    <div className="pagina-em-branco__icone">
      <FontAwesomeIcon icon={icone} size={tamanhoDoIcone} />
    </div>
    <h3 className="pagina-em-branco__mensagem">{mensagem}</h3>
  </div>
);

BlankSlate.propTypes = {
  icone: PropTypes.object,
  mensagem: PropTypes.string,
  tamanhoDoIcone: PropTypes.string,
};

export default BlankSlate;
