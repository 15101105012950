import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Rodape, CampoTextBox, ListaPaginada, SorteadoItem, Dialogo } from '../../components';
import '../../estilos/estilos.scss';
import { obterSorteados, deletarSorteados } from '../../actions/sorteadosActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

class Home extends Component {
  constructor() {
    super();
    this.state = {
      identificadorDoSorteado: '',
      nenhumResultadoEncontrado: false,
      deveExibirDialogo: false
    };
  }

  onChange = event => {
    const { value } = event.target;
    this.setState({
      identificadorDoSorteado: value,
    });
  };

  async buscarSorteados(pagina = 1, event) {
    if (event) event.preventDefault();

    try {
      await this.props.obterSorteados(this.state.identificadorDoSorteado, pagina);
      this.setState({ nenhumResultadoEncontrado: false });
    } catch {
      this.setState({ 
        nenhumResultadoEncontrado: true,
        deveExibirDialogo: true
      });

      this.props.deletarSorteados();
    }
  }

  montarItemDaLista = (sorteado) => {
    return <SorteadoItem key={sorteado.numeroDaInscricao} sorteado={sorteado} />;
  };

  fecharDialogo = () => {
    this.setState({ deveExibirDialogo: false });
  }
  
  fecharDialogoComEsc = (event) => {
    if(event.keyCode === 27) {
      this.fecharDialogo();
    }
  }

  criarDialogo = () => {
    const dialogo = {
      titulo: 'O candidato pesquisado não foi localizado.',
      mensagem: 'Sua inscrição não foi sorteada para lista principal ou reserva. Verifique se as informações digitadas na busca estão corretas.',
      textoDoBotaoFechar: 'Realizar nova pesquisa',
      icone: faInfoCircle
    }

    if (this.state.deveExibirDialogo && this.state.nenhumResultadoEncontrado) {
      return <Dialogo titulo={dialogo.titulo} mensagem={dialogo.mensagem} icone={dialogo.icone} textoDoBotaoFechar={dialogo.textoDoBotaoFechar} fecharDialogo={this.fecharDialogo} />;
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.fecharDialogoComEsc, false);
  }
  
  componentWillUnmount() {
    document.removeEventListener('keydown', this.fecharDialogoComEsc, false);
  }

  render() {
    const inputEvents = {
      onChange: this.onChange,
    };

    const sorteados = this.props.sorteados;
    return (
      <div className="pagina">
        <Header />
        <main className="pagina__corpo">
          <div className="pagina__container">
            <h1 className="titulo titulo_grande pagina__titulo">
              Lista de Sorteados do Programa Minha Casa Minha Vida de Mato Grosso do Sul
            </h1>
            <form
              onSubmit={e => this.buscarSorteados(1, e)}
              className="formulario pagina__formulario"
            >
              <fieldset>
                <legend>Pesquisa por Candidato</legend>
                <div className="grade">
                  <div className="grade__linha">
                    <CampoTextBox
                      label="CPF ou Nome"
                      placeholder="Digite o CPF ou Nome do candidato"
                      type="text"
                      name="inputDoSorteado"
                      required="required"
                      {...inputEvents}
                    />
                    <div className="grade__coluna grade__coluna_estreita">
                      <button
                        className="botao botao_medio botao_cor-sucesso botao_com-icone-para-esquerda"
                      >
                        <FontAwesomeIcon icon={faSearch} /> Pesquisar
                      </button>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
            <ListaPaginada
              itens={sorteados.modelo ? sorteados.modelo.map(this.montarItemDaLista) : []}
              paginaAtual={sorteados.paginaAtual}
              totalDePaginas={sorteados.totalDePaginas}
              navegarParaAPagina={this.buscarSorteados.bind(this)}
              exibirMensagemParaNenhumResultadoEncontrado={this.state.nenhumResultadoEncontrado}
            />
          </div>
        </main>
        <Rodape />
        {this.criarDialogo()}
      </div>
    );
  }
}

const mapStateToProps = ({ sorteados }) => ({
  sorteados,
});

const mapDispatchToProps = dispatch => ({
  obterSorteados: (identificadorDoSorteado, pagina) => dispatch(obterSorteados(identificadorDoSorteado, pagina)),
  deletarSorteados: () => dispatch(deletarSorteados()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
