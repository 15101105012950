import { apiDaConsultaPublica } from './axios';
//TODO refatorar para trazer as URLS de outro lugar
var urlBase = 'http://sorteador.habix.agehab.ms.gov.br/api';

const obterSorteados = async (identificadorDoSorteado, pagina) => {
    let hostAtual = window.location.host;
    if (hostAtual.includes('hom')) {
        urlBase = 'http://hom.sorteador.habix.agehab.ms.gov.br/api';
    } else if (hostAtual.includes('digix') || hostAtual.includes('azurecontainer')) {
      urlBase = 'http://habixsorteador.brazilsouth.azurecontainer.io/api';
  }

  const ehAmbienteDeTesteE2E = process != null && process.env != null && process.env.REACT_APP_AMBIENTE_E2E;
  if(window.location.host.includes('localhost') && !ehAmbienteDeTesteE2E){
    urlBase = 'http://localhost:3000/sorteador/api';
  }

  const url = `${urlBase}/processosDeSorteio/sorteadosParaConsultaPublica?identificacaoDoSorteado=${identificadorDoSorteado}&pagina=${pagina}`;
  return await apiDaConsultaPublica().get(url);
};

export default { obterSorteados };
