import React from 'react';
import { ReactComponent as LogoHabix } from '../Logos/logo-habix.svg';
import { ReactComponent as LogoDigix } from '../Logos/logo-digix.svg';

const Rodape = () => (
  <footer className="rodape">
    <div className="rodape__container">
      <a className="rodape__link" href="http://www.digix.com.br/">
        <LogoDigix className="rodape__logo-digix" alt="Digix" />
      </a>

      <a className="rodape__link" href="http://www.agehab.ms.gov.br/">
        <LogoHabix className="rodape__logo-habix" alt="Habix - Um produto Digix" />
      </a>
    </div>
  </footer>
);

export default Rodape;
