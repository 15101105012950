import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router';
import { Provider } from 'react-redux';
import configureStore from './store';
import { configurarAxios } from './servicos/axios';
import { Home } from './telas';
import initialState from './reducers/initialState';
import Loading from './components/Loading';

const store = configureStore(initialState);
configurarAxios(store);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Route exact path="/" component={Home} />
      <Loading />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);
