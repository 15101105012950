import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';

function CriterioItem({ foiAtendido, nomeDoCriterio }) {
  const icone = foiAtendido ? faCheckCircle : faTimesCircle;
  const classe = foiAtendido ? 'criterio__item_atendido' : 'criterio__item_nao-atendido';

  return (
    <div className={`criterios__item ${classe}`}>
      <FontAwesomeIcon icon={icone} />
      <p className="criterios__descricao">{nomeDoCriterio}</p>
    </div>
  );
}

CriterioItem.propTypes = {
  nomeDoCriterio: PropTypes.string,
  foiAtendido: PropTypes.bool,
};

export default CriterioItem;
