import React, { Component } from 'react';
import '../../estilos/estilos.scss';
import PropTypes from 'prop-types';
import { BlankSlate } from '../';
import { faSearch, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import SeletorDePagina from './SeletorDePagina';

class ListaPaginada extends Component {
  exibirBrankSlate = () => {
    let mensagem = 'Utilize o campo de pesquisa acima para encontrar as informações desejadas.';
    let icone = faAngleUp;
    
    if (this.props.exibirMensagemParaNenhumResultadoEncontrado) {
      mensagem = 'Nenhum resultado encontrado.';
      icone = faSearch;
    }

    return <BlankSlate mensagem={mensagem} icone={icone} tamanhoDoIcone="6x" />;
  };

  render() {
    const { itens, totalDePaginas, paginaAtual, navegarParaAPagina } = this.props;

    return (
      <div>
        <ol className="lista">
          {itens.length ? itens : this.exibirBrankSlate()}
        </ol>

        <SeletorDePagina
          paginaAtual={paginaAtual}
          totalDePaginas={totalDePaginas}
          navegarParaAPagina={navegarParaAPagina}
        />
      </div>
    );
  }
}

ListaPaginada.propTypes = {
  itens: PropTypes.array,
  paginaAtual: PropTypes.number,
  totalDePaginas: PropTypes.number,
  navegarParaAPagina: PropTypes.func,
};

export default ListaPaginada;
